@use 'vars' as *;

.t-home{
    header.top{
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-attachment: fixed;
        pointer-events: none;
        .header-bar-bottom{
            padding-bottom: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            pointer-events: all;
            .subtitle{
                & > *{
                    margin:0;
                    font-size: 16px;
                }
                & >:first-child{
                    font-size: 10vw;
                    line-height: 7vw;
                }
                & >:nth-child(2){
                    font-size: 14vw;
                    line-height: 12vw;
                    margin-bottom: 4rem;
                    font-weight: 900;
                }
                max-width: none;
                margin:0;
                padding-left: 0;
                display: block;
                align-items: initial;
            }
           
            a.btn-outline{
                // text-align: center;
            }
            
        }
    }
    .cta-banner, .shop-banner{
        padding-top: 6rem;
        padding-bottom: 6rem;
        .title{
            color:$red;
            padding-bottom: 2rem;
            display: flex;
            flex-wrap: wrap;
            .black-size{
                font-size: 54px;
                @media screen and (max-width: 576px) {
                    font-size: 10vw
                }
            }
            .cursive{
                font-size: 64px;
                padding-left:1rem;
                @media screen and (max-width: 576px) {
                    font-size: 11vw
                }
            }
            .red-btn{
                margin: auto;
                font-size: 16px;
                margin-right: 0;
                @media screen and (max-width: 576px) {
                    font-size: 9vw
                }
            }
        }
        .banners{
            .banner-stage{
                display: grid;
                // height: 40vw;
                grid-auto-flow: column;
                grid-gap: 1.75rem;
                
                @media only screen and (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-flow: initial;
                }
                .banner{
                    text-decoration: none;
                    color: $red;
                    .banner-content{
                        position: relative;
                        cursor: pointer;
                    }
                    .banner-content:hover::before{
                        content:"";
                        position: absolute;
                        top:0;
                        height: 30vw;
                        left:0;
                        right:0;
                        background-color: rgba(0,0,0,.4);
                        z-index: 2;
                        @media only screen and (max-width: 768px) {
                            height: 60vw;
                        }
                    }
                    .banner-content:hover::after{
                        content: "VIEW";
                        position: absolute;
                        top: 60%;
                        color: white;
                        border: 1px solid white;
                        width:fit-content;
                        min-width: 50%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        text-align: center;
                        font-weight: bold;
                        padding: .3rem 1rem;
                        z-index: 3;
                        // @media only screen and (max-width: 768px) {
                        //     height: 60vw!important;
                        // }
                    }

                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 30vw!important;
                        position: relative;
                        @media only screen and (max-width: 768px) {
                            height: 60vw!important;
                        }
                     
                    }
                  
                    .banner-name{
                        font-size: 16px;
                        display: block;
                        text-align: center;
                        text-transform: uppercase;
                        font-weight: bold;
                        padding-top:1.5rem;
                    }
                }
            }
            .banner-navigation{
                display: none;
            }
        }
    }        

    .iphone-fixed-bg {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: -1;
    }
    
    .banners-home {
        position:relative;
        .banner {
            opacity: 0;
            pointer-events: none;
            display:block;
            background-position:center;
            background-size:cover;
            display:none;
            img{
                width: 100vw;
                height: 80vh!important;
                @media only screen and (max-width: 1200px) {
                    height: 60vw!important;
                }
            
                object-fit:cover;
            }
            &.active{
                display: initial;
                opacity: 1;
                pointer-events: initial;
            }
            .banner-title{display: none;}
            .banner-caption{
                position:absolute;
                left:0;
                bottom:0;
                width: 100%;
                text-align: center;
                background-color: rgba(0,0,0,.4);
                color:white;
                padding:2rem 20px;
                font-weight: bold;
                font-size: 16px;
                span{
                    max-width: 1400px;
                    margin:0 auto
                }
            }
        }
        .banner-navigation, .banner-name {
            display:none;
        }
    }
    /*======= Banner navigation arrow ======*/
    .navigation-arrows {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        height: 100%;
        box-sizing: border-box;
        width: 100%;
        pointer-events: none;
        .red-box{
            background-color: $red;
            padding:1rem;
            pointer-events: all;
            cursor: pointer;
            @media only screen and (max-width: 576px) {
                padding: .25rem;
            }
            &:hover{
                background-color: darken( $red, 5% )
      
            }
        }
        .move-next > .arrow{
            display: block;
            height: 30px;
            width: 30px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            transform: rotateY(0deg) rotate(45deg);
            margin: .5rem 1rem;
            margin-left: 0px;
        }

        .move-prev > .arrow {
            display: block;
            height: 30px;
            width: 30px;
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            transform: rotateY(0deg) rotate(-45deg);
            margin: .5rem 1rem;
            margin-right: 0px;
        }
    }
}