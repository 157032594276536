@use 'vars' as *;

.t-products{
    .red-btn{
        font-weight: bold;
    }
    .product-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3rem 1rem;
        padding:5rem 20px;
        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 400px) {
            grid-template-columns: 1fr;
        }
        .title{
            color: $red;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin-top:2rem;
        }
        .overlay{
            position: relative;
            cursor: pointer;
            &:hover::before{
                content: "";
                position: absolute;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                width: 100%;
            }
            &:hover::after{
                content: "VIEW PRODUCT";
                position: absolute;
                top: 60%;
                color: white;
                border: 1px solid white;
                width: 170px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                font-weight: bold;
                padding: .3rem 0;
            }
        }
        .overlay > img{
            height: 30vw!important;
            width: 100%;
            object-fit: cover;
            @media only screen and (max-width: 900px) {
                height: 55vw!important;
            }
            @media only screen and (max-width: 400px) {
                height: 75vw!important;
            }
        }
    }

    a{
        text-decoration:none;
    }
    .arrow-left{
        display: inline-block;
        height: 14px;
        width: 14px;
        border-top: 1px solid $red;
        border-left: 1px solid $red;
        transform: rotateY(
                0deg
            ) rotate(
                -45deg
            );

        
    }
    .products-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        grid-gap: 4rem 2rem;
        padding:5rem 20px;
        .title{
            color: $red;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin-top:2rem;
        }
        .overlay{
            position: relative;
            cursor: pointer;
            height: 90%;
            display: flex;
            img{
                height: 100%!important;
                width: 100%;
                min-height: 25rem;
                object-fit: cover;
               
            }
            .fallback img{
                background-color: black;
                padding:1rem;
                object-fit: contain;
            }
            
            
            &:hover::before{
                content: "";
                position: absolute;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                width: 100%;
            }
            &:hover::after{
                content: "VIEW PRODUCT";
                position: absolute;
                top: 60%;
                color: white;
                border: 1px solid white;
                width: 170px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                font-weight: bold;
                padding: .3rem 0;
            }
        }
        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            .overlay{
                height: 100vw;
            }
        }
    }
    .product-item-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        margin-top:3rem;
        @media only screen and (max-width: 900px) {
            grid-template-columns: 1fr;
        }
        .gallery{
            display: grid;
            grid-template-columns: 0.8fr 0.2fr;
            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr;
            }
            grid-gap: 2rem;
            
            & > picture > img{
                object-fit: cover;
                width: 100%;
                height: 30rem !important;
            }
            & > .selection {

                display:grid;
                grid-template-columns: 100%;
                // grid-template-rows: auto;
                
                grid-auto-rows:9rem;
                grid-gap: .5rem;
                @media only screen and (max-width: 900px) {
                    display:flex;
                    justify-content: center;
                    flex-wrap: wrap;
                   
                }
                & > picture {
                    position: relative;
                    cursor: pointer;
                    & > img{
                        
                        object-fit: cover;
                        width: 100%;
                        height: 100% !important;
                        
                        @media only screen and (max-width: 900px) {
                            width: auto;
                            height: 25vw!important;
                        }
                   
                    }
                    &:hover::before{
                        content: "";
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.4);
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        
    }

    .page-title{
        line-height: .7;
        margin-top: 0;
        margin-bottom: 2rem;
        color:$red;
        font-weight: bold;
        text-transform: uppercase;
        @media only screen and (max-width: 900px) {
            font-size: 5vw;
        }
        @media only screen and (max-width: 576px) {
            font-size: 6vw;
        }
    }
    .page-body{
        margin: 0;
    }
    .page-content{
        padding:3rem 20px;
    }
    .date{
        color:$red;
        margin:10px 0;
        text-transform: uppercase;
    }
    .description{
        margin-top:3rem;
        margin-bottom:0;
    }
    .purchase-form{
        display:none;
    }
    .related-products{
        margin:5rem 0;
        .title-bar{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .title{
                font-size:36px;
            }
            .red-btn{
                line-height: 34px;
            }
        }
        .cards-wrapper{
            margin-top: 2rem;
            display:grid;
            grid-template-columns: repeat(4, 1fr);
      
            grid-gap: 1rem;
            @media only screen and (max-width: 576px) {
                grid-auto-rows: auto;
                grid-template-columns: repeat(2, 1fr);
            }
            @media only screen and (max-width: 300px) {
                grid-template-columns: 1fr;
            }
            .card{
                height: fit-content;
                .title{
                    text-transform: uppercase;
                    text-align: center;
                    color:$red;
                    font-weight: bold;
                }
                picture{
                    position: relative;
                    display: block;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%!important;
                        object-fit: cover;
                        
                    }
                    &.fallback img{
                      
                        background-color: black;
                        padding:1rem;
                        object-fit: contain;
                    }
                    &:hover::before{
                        content: "";
                        position: absolute;
                        background-color: rgba(0, 0, 0, 0.4);
                        height: 100%;
                        width: 100%;
                    }
                    &:hover::after{
                        content: "VIEW PRODUCT";
                        position: absolute;
                        top: 60%;
                        color: white;
                        border: 1px solid white;
                        width: 170px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        text-align: center;
                        font-weight: bold;
                        padding: .3rem 0;
                    }
                }
            }
        }
    }
    
    .variant {
        .variant-title{
            text-transform: uppercase;
        }
        input[type=radio] {
            display: none;
        }

        label {
            display: inline-block;
            background-color: transparent;
            border:1px solid black;
            color:black;
            padding: 4px 11px;
            font-size: 16px;
            cursor: pointer;
        }

        input[type=radio]:checked+label {
            border:1px solid $red;
            color:$red;
        } 
    }
    .price{
        margin:0;
        font-size:36px;
    }
    .price-note{
        margin:0;
    }
    .buy-now, button[type=submit]{
        margin-top:2rem;
        border:1px solid $red;
        text-transform: uppercase;
        font-weight: 700;
        padding:.8rem 1rem;
        background-color: transparent;
        color:$red;
        cursor: pointer;
    }
    .main-img{
        display: flex;
        justify-content: center;
        img{
            width:auto;
            height: 50vw!important;
            object-fit: contain;
            object-position: top;
        }
    }

    form.floatlabel-active{
        .wtwidget-TextInput, .wtwidget-EmailInput, .wtfield-PasswordField {
            label{
                position:absolute;
                top:18px;
                left:12px;
                transition: all 0.5s ease;
                font-size:16px;
                font-weight: bold;
                text-transform: uppercase;
            }
            &.focused, &.populated{
                label{
                    top:0;
                    left:2px;
                    font-size:14px;
                }
            }
        }
    }

    .form-group{
        position: relative;
        input, select, textarea{
            border: none;
            border-bottom: 1px solid #000;
            outline: none;
            width: 100%;
            margin: 1rem 0;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 10px;
            
            font-family: proxima-nova, sans-serif;
            &:focus{
                background-color: #F2F2F2;
                border-bottom:1px solid $red;
            }
        }
        select{
            padding-left: .5rem;
            text-transform: uppercase;
        }
        h3{ 
            color:$red;
            text-transform: uppercase;
            font-weight: bold;
        }
        hr{
            margin:3rem 0;
        }
    }
    .checkout{
        h2, h3{
            color:$red;
            text-transform: uppercase;
            margin-top:2rem;
        }
        table{
            th{
                text-align: right;
            }
            td{
                text-align: left;
                padding-left:5px;
            }
        }
    }
}