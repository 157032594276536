@use 'vars' as *;

.t-contact{
    .contact-wrapper{
        font-weight: bold;
        margin:2rem 0;
        text-transform: uppercase;
        .site-links{
            display: flex;
            flex-direction: column;
            margin-bottom:2rem;
            .site-link{
                margin:0;
                margin-top:.5rem;
                display:flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: flex-end;
               
                .social-link-icon{
                    width:30px;
                    margin-left: 1rem;
                }
            }
            
        }
        .email.item{
            margin:2rem 0;
        }
        h1{
            font-size: 4vw;
        }
    }
    .two-column{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:1.5rem;
        @media only screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            .map-wrap{
                grid-row:1;
                margin-top: 0;
                margin-bottom: 2rem;
                height: 40vw;
            }
        }
    }
    .item .label{
        width: 100px;
        display: inline-block;
    }
    .footer-address {
        display:flex;
    }
    .member-wrapper{
        
        @media only screen and (max-width: 760px) {
            text-align:center;
        }
        h1{
            font-size: 4rem;
            @media only screen and (max-width: 1200px) {
                font-size: 5vw;
            }
            @media only screen and (max-width: 760px) {
                font-size: 10vw;
            }
        }
    }
    .form-result-wrap{
        text-align: center;
        #form-result{
            color:$red;
            text-transform: uppercase;
            font-weight: bold;
            font-family: meritocracy, cursive;
            font-size: 5rem;
            margin-bottom: 5px;
        }
    }
    
    .form-wrapper{
        margin-top:2rem;
        input[type=submit], .btn-primary{
            border: 1px solid $red;
            padding: 2rem;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            line-height: 0;
            display: block;
            background-color: transparent;
            color: $red;
            transition: all .5s ease;
            text-transform: uppercase;
            &:hover{
                color: darken($red, 20%);
                border: 1px solid darken($red, 20%);
                font-size: 1.5em;
            }
        }
    
        input, select, textarea{
            border: none;
            border-bottom: 1px solid #000;
            outline: none;
            width: 100%;
            margin: 1rem 0;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 10px;
            
            font-family: proxima-nova, sans-serif;
            &:focus{
                background-color: #F2F2F2;
                border-bottom:1px solid $red;
            }
        }
        select{
            padding-left: .5rem;
            text-transform: uppercase;
        }
    }
    .recaptcha-notice{
        padding-left: calc(50% + .5rem);
        width: 80%;
        font-weight: normal;
        @media only screen and (max-width: 760px) {
            padding-left: 0;
            width: 100%;
            padding-right: 6rem;
        }
        a{
            text-decoration: underline;
        }
    }
    .message-wrap{
        position: relative;
        padding: 10px 0;
        .js-float-wrap{
            height: 100%;
            padding-bottom: 16px;
            margin: 0;
            textarea{
                height: 100%;
                margin: 0;
            }
        }
        
        .button{
            position: absolute;
            width: min-content;
            right: 0;
            color:$red;
            background-color: transparent;
            border:none;
            font-weight: bold;
            font-size: 16px;
        }
        
    }
}