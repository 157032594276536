@use 'vars' as *;

.t-events{
    .no-content{
        min-height: 200px;
        font-size: 32px;
        
        padding:2rem 20px;
    }
    a{
        text-decoration:none;
        display: block;
        &.button{
            color:$red;
            background-color: transparent;
            border-color: $red;
            &:hover{
                background-color: $red;
                color: white;
            }
        }
    }
    .arrow-left{
        display: inline-block;
        height: 14px;
        width: 14px;
        border-top: 1px solid $red;
        border-left: 1px solid $red;
        transform: rotateY(
                0deg
            ) rotate(
                -45deg
            );

        
    }
    .event-filters{
        display: flex;
       
      
        justify-content: flex-start;
        flex-wrap: wrap;
        padding:20px;
        padding-top:3rem;
        @media only screen and (max-width: 760px) {
            padding-top:1.5rem;
        }
        a{
            margin-top: 1rem;
            border: 1px solid #F95D55;
            text-transform: uppercase;
            font-weight: 700;
            padding: 0.8rem 1rem;
            background-color: transparent;
            color: #F95D55;
            transition: all .3s ease-in-out;
            &.active, &:hover{
                background-color: #F95D55;
                color: white;
            }
            margin-right: 1rem;
        }
    }
    .events-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        grid-gap: 4rem 2rem;
        padding:5rem 20px;
        padding-top: 2rem;
        & > div{
            object-fit: cover;
            display: flex;
            flex-direction: column;
            // @media only screen and (max-width: 576px) {
            //     grid-template-columns: 1fr;
            // }
        }
        .title{
            height: fit-content;
            text-align: center;
            & > a{
                color: $red;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
           
             
            }
        } 
        .image-wrapper{
            aspect-ratio: 9/16;
            position: relative;
            width: 100%;
            display: flex;

            @media only screen and (max-width: 992px) {
                aspect-ratio: 9/14;
            }
            // @media only screen and (max-width: 760px) {
            //     height: 90vw;
               
            // }
            .summary{
                color:white;
                padding:1.5rem;
                font-weight: 700;
                font-size: clamp(16px, 1.5vw, 24px);
                @media only screen and (max-width: 300px) {
                    display: none;
                }
            }
            img{
                height: 100%!important;
                width: 100vw;
                // min-height: 25rem;
                object-fit: cover;
               
            }
            .fallback img{
                background-color: black;
                padding:1rem;
                object-fit: contain;
            }
            
            
            .button{
                position: relative;
                // top: 60%;
                color: white;
                border: 1px solid white;
                width: 170px;
                margin: 0 auto;
                text-align: center;
                font-weight: bold;
                padding: .3rem 0;
                margin-bottom: 10px;
                &:hover{
                    color:white;
                    background-color: $red;
                    border-color: $red;
                }
                @media only screen and (max-width: 300px) {
                    width: 90%;
                }
            }
            .overlay{
                display: flex;
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.6);
                transition: all .3s ease-in-out;
                flex-direction: column;
                justify-content: center;
                opacity: 0;
            }
            &:hover .overlay{
                opacity: 1;
            }
        }
        // @media only screen and (max-width: 1200px) {
        //     grid-template-columns: repeat(2, 1fr);
        // }
        @media only screen and (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
           
        }
        @media only screen and (max-width: 576px) {
            grid-template-columns: 1fr;
        }
    }
    .event-item-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        margin-top:3rem;
        @media only screen and (max-width: 992px) {
            grid-template-columns: 1fr;
        }
        .gallery{
            display: grid;
            grid-template-columns: 0.8fr 0.2fr;
            grid-gap: 2rem;
            @media only screen and (max-width: 1000px) {
                grid-gap: 1rem;
                grid-template-columns:1fr;
            }
            // @media only screen and (max-width: 576px) {
                
            // }
            & > picture > img{
                object-fit: cover;
                width: 100%;
                height: 30rem !important;
            }
            & > .selection {

                display:grid;
                grid-template-columns: 100%;
                grid-template-rows: auto;
                align-items: center;
                // grid-auto-rows:9rem;
                grid-gap: .5rem;
                // @media only screen and (max-width: 1000px) {
                //     grid-gap: 2rem;
                // }
                @media only screen and (max-width: 1000px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 5px;
                }
                @media only screen and (max-width: 300px) {
                    grid-template-columns: 1fr 1fr;
                }
                
                & > picture {
                    position: relative;
                    cursor: pointer;
                    & > img{
                        
                        object-fit: cover;
                        width: 100%;
                        height: 10rem !important;
                        @media only screen and (max-width: 1000px) {
                            height: auto !important;
                        }
                    }
                    &:hover::before{
                        content: "";
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.4);
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        
    }
    .main_img img{
        width: 100%;
        max-height: 80vw;
        object-fit: contain;
        @media only screen and (max-width: 576px) {
            max-height: 80vh;
            object-fit: cover;
        }
    }
    .page-title{
        line-height: .7;
        margin-top: 0;
        margin-bottom: 2rem;
        color:$red;
        font-weight: bold;
        text-transform: uppercase;
    }
    .page-body{
        margin: 0;
    }
    .page-content{
        padding:3rem 20px;
    }
    .date{
        color:$red;
        margin:10px 0;
        text-transform: uppercase;
    }
    .description{
        margin-top:3rem;
        margin-bottom:0;
    }
    
    .related_events{
        margin:5rem 0;
        .title-bar{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .title{
                font-size:36px;
            }
            .red-btn{
                line-height: 34px;
            }
        }
        .events-grid{
            margin-top: 2rem;
            padding:0;
        }
    }
}
