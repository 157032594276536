
@use 'vars' as *;
@use 'register';
@use 'product';
@use 'sponsors';
@use 'contact';
@use 'membership';
@use 'events';
@use 'index';

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
a[href='']{
    pointer-events: none;
}
body {
    padding:0;
    margin:0;
    font-size:16px;
    font-family: proxima-nova, sans-serif;
    min-width: 240px;
}
.t-page .page-content{
    padding:4rem 5px;
}
.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 250px;
    height:auto;
}

/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
header a,
footer a,
.contact-details a  {
    text-decoration: none;
    color: inherit;
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 1;
    font-size: 0.75em;
}
form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}

/*============ header ============*/
header.top {
    font-family: proxima-nova, sans-serif;
    font-size:14px;
    background-color: black;
    background-position: center;
    background-size: cover;
    position: relative;

    min-height: 25vw;
    &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }
    
}
.header-bar-top {
    // color:#ffffff;
    // background: #070707;
    background-size:cover;
    pointer-events: all;
    position: relative;
    /* this should be on top of the bottom header bar because it contains the mobile menu */
    z-index: 12;
    
    .wrapper *{
        z-index: 100!important;
    }
}
.header-bar-bottom{
    pointer-events: none;
    z-index: 10;
    // padding-left:4rem;
    .subtitle{
        p{
            margin-top: 5.5vw;
        }
        max-width: 1200px;
        margin: 0 auto;
        font-size: 73px;
        padding-left:20px;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 900px) {
            font-size: 10vw;
        }
       
    }
}
header.top ul {
    text-transform: uppercase;
}
header.top li {
    vertical-align:top;
}
.header-menu {
    font-size: 16px;
    color:rgba(255 ,255 ,255 , .4);
    display:flex;
    font-weight: bold;
    .wrapper{
        display:flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding:0 2rem;
        @media only screen and (max-width: 1200px) {
            padding:0 1rem;
        }

        .club-day{
            display: flex;
            align-items: center;
            .date{
                color:white;
                text-transform: uppercase;
            }
            & > *{
                padding: 0 1rem;
            }
            @media only screen and (max-width: 1200px) {
                flex-direction: column;
                justify-content: center;
                align-items: baseline;
            }
        }
    }
}
a.logo{
    background-color: black;
    padding:20px;
    padding-right: 0;
    img{
        width:180px;
    }
}
.header-menu > ul {
    text-align:right;
}

.header-bar-bottom {
    color:#fff;
    height: 100%;
    position:relative;
}
.main-menu{
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    margin-left: auto;
    a {
        padding:15px 0;
    }
    // all list
    ul {
        text-align:justify;
        line-height:0;
    }
    //all list and list items
    ul, li{
        line-height:1;
    }

    li{
        
        // sub list
        & > ul {
            position:absolute;
            width: fit-content;
            top: 100%;
            left:0;
            right:0;
            background:#f6f6f6;
            color:#082133;
            z-index:10000;
            text-align: left;
            display:none;
            &::before, &::after{
                content:"";
                background:transparent;
                position:absolute;
                top:0;
                bottom:0;
                width:0;
                height:100%;
            }
            &::before {
                left:0;
                padding-left:50vw;
                margin-left:-50vw;
        
            }
            &::after {
                right:0;
                padding-right:50vw;
                margin-right:-50vw;
        
            }
        }
        &:hover 
        {
            color:#fff;
            background-color: $red;
            & > ul {
                display: flex;
                flex-direction: column;
            }
        }
    }
    //1st level list
    & > ul{

        display: flex;
        min-width: fit-content;

        flex-wrap: wrap;
    }

    //1st level items
    li.depth-0 {
        vertical-align:middle;
        position: relative;
        margin:0 10px;
        white-space: nowrap;
        &:hover 
        {
            color:#fff;
            background-color: transparent;
        }
        &.active{
            color:white;
        }
        &:last-child ul{
            left:unset;
        }
    }
    //sub level items
    li.depth-1 {
        position: relative;
        .title {
            font-size:18px;
        }
        a {
            min-width: 160px;
            padding: 15px;
        }
        ul{
            left: 100%;
            top:0;
            li{
                position: relative;
            }
        }
    }
}


#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:1000!important;
    background-color: #F95D55;
    padding:8px;
    box-sizing: content-box;
    border-radius: 5px;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #000;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: #fff;
    color:black;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:$red;
	background:#fff;
	margin:0;
	font-weight:bold;
    border-bottom:solid 1px $grey;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
    background:#eee;
	a{
        color: $grey-text;
    }
}
.slide-menu ul ul ul{
	background:#ddd;
    a{
        color: $grey-text;
    }
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #000;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
    transition: all .1s ease;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	// font-size:1.1em;
    font-weight: bold;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    height: 59px;
    width: 50px;
    cursor: pointer;
    background-color: $red;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/*============ footer ============*/
.footer-banner{
    .footer-cta{
        position: relative;
        display: flex;
        height: max(300px, 50vw);

        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-position: center;
        background-size: cover;
        ul{
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-evenly;
            width:100%;
            max-width: 1200px;
            z-index: 2;
            list-style: none;
            padding-bottom: 1rem;
            @media screen and (max-width: 576px) {
                display: none;
            }
            a{
                color:$red;
                font-weight: bold;
                text-decoration: none;
                font-size: 16px;
            }
           
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 1;
            right: 0;
            bottom: 0;
            pointer-events: none;
        }
        & > .title{
            z-index: 2;
            color:white;
            .black-size{
                font-size:136px;
               
            }
            .cursive{
                margin-left: 3rem;
                font-size:191px;
                @media screen and (max-width: 576px) {
                    margin-left: 1rem;
                }
            }
            @media only screen and (max-width: 1300px) {
                .black-size{
                    font-size: 12vw;
                }
                .cursive{
                    font-size: 12.5vw;
                }
            }
            @media only screen and (max-width: 900px) {
                .black-size{
                    font-size: 11vw;
                }
                .cursive{
                    font-size: 12vw;
                }
            }
        }
    }
    .member-banner{
        background-color: $red;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
        flex-wrap: wrap;
        padding: 20px;
        @media screen and (max-width: 1100px) {
            text-align: center;
            padding: 10px;
        }
        .black-size{
            font-size: 54px;
            @media screen and (max-width: 1100px) {
                font-size: 5vw;
            }
        }
        .cursive{
            font-size: 76px;
            margin-left:2rem;
            @media screen and (max-width: 1100px) {
                font-size: 7vw;
                margin-left: 1rem;
            }
        }
    }
}

footer .footer-bottom {
    background-color:$grey;
    color:#fff;
    padding-top:2rem;
    .footer-wrapper{
        & > * {
            padding-top:2rem;
        }
        display: flex;
        align-items: flex-start;
        font-weight: bold;
        padding-bottom:2rem;
        @media only screen and (max-width: 1200px) {
            & > div:first-child{
                width: 100%;
                flex-basis:100%;
                margin-bottom:2rem;
            }
            flex-wrap: wrap;
        }
        a.logo{
            padding-right: 4rem;
        }
        .club-wrapper{
            max-width: 450px;
            margin:0 7vw;
            @media only screen and (max-width: 1200px) {
                margin:0 2rem;
            }
            h1{
                margin-top:0;
                font-weight: 900;
                font-size: 55px;
                line-height: 45px;
                @media only screen and (max-width: 576px) {
                    font-size: 10vw;
                }
            }
            .club-day{
                color:rgba(255, 255, 255, .4);
                font-size:16px;
            }
            .date{
                text-transform: uppercase;
                margin-left:1rem;
                color:white;
            }
        }
        .site-links{
            display:flex;
            flex-direction: column;
            margin-top:2rem;
            .site-link{
                margin-left: 0;
                display:flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                text-transform: uppercase;
                font-size:16px;
                img{
                    width: 30px;
                    margin-right: 1rem;
                }
            }
        }
        & > .footer-menu{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin:0;
            grid-gap:2rem;
            list-style: none;
            font-size: 16px;
            max-width: 50%;
            padding-right:  40px;
            padding-left:  40px;

            @media only screen and (max-width: 1200px) {
                max-width: none;
                width: 100%;
            }
            
            @media only screen and (max-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap:1rem;
            }

            @media only screen and (max-width: 300px) {
                grid-template-columns: 1fr;
            }
            li.top{
                display: flex;
                flex-direction: column;
                text-transform: uppercase;
                &:hover{
                    color: darken(white, 10%);
                }
                & > ul{
                    list-style: none;
                    padding:0;
                    color:$grey-text;
                    li:hover{
                        color: lighten($grey-text, 10%);
                    }
                }
            }
        }
    }
 

}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}

.red{
    color:$red;
}

/*============ sub page============*/
.page-content {
    padding:5px;
}
.page-graphic {
    padding-top:27.3%;
    background-size:cover;
    background-position:center;
    position:relative;
    display:block;
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

/*============ Contact ============*/
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 320px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .header-menu,
    .main-menu {
        display:none;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }


}

@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}