@use 'vars' as *;

.t-register{
    a{
        text-decoration:none;
        display: block;
    }
    .arrow-left{
        display: inline-block;
        height: 14px;
        width: 14px;
        border-top: 1px solid $red;
        border-left: 1px solid $red;
        transform: rotateY(
                0deg
            ) rotate(
                -45deg
            );

        
    }
    .events-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        grid-gap: 4rem 2rem;
        padding:5rem 20px;
        .title{
            color: $red;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin-top:2rem;
        }
        .overlay{
            position: relative;
            cursor: pointer;
            height: 90%;
            display: flex;
            img{
                height: 100%!important;
                width: 100vw;
                min-height: 25rem;
                object-fit: cover;
               
            }
            .fallback img{
                background-color: black;
                padding:1rem;
                object-fit: contain;
            }
            
            
            &:hover::before{
                content: "";
                position: absolute;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                width: 100%;
            }
            &:hover::after{
                content: "VIEW EVENT";
                position: absolute;
                top: 60%;
                color: white;
                border: 1px solid white;
                width: 170px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                font-weight: bold;
                padding: .3rem 0;
            }
        }
        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            .overlay{
                height: 100vw;
            }
        }
    }
    .event-item-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        margin-top:3rem;
        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
        .gallery{
            display: grid;
            grid-template-columns: 0.8fr 0.2fr;
            grid-gap: 2rem;
            @media only screen and (max-width: 1000px) {
                grid-gap: 1rem;
                grid-template-columns:1fr;
            }
            // @media only screen and (max-width: 576px) {
                
            // }
            & > picture > img{
                object-fit: cover;
                width: 100%;
                height: 30rem !important;
            }
            & > .selection {

                display:grid;
                grid-template-columns: 100%;
                grid-template-rows: auto;
                align-items: center;
                // grid-auto-rows:9rem;
                grid-gap: .5rem;
                // @media only screen and (max-width: 1000px) {
                //     grid-gap: 2rem;
                // }
                @media only screen and (max-width: 1000px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 5px;
                }
                @media only screen and (max-width: 300px) {
                    grid-template-columns: 1fr 1fr;
                }
                
                & > picture {
                    position: relative;
                    cursor: pointer;
                    & > img{
                        
                        object-fit: cover;
                        width: 100%;
                        height: 10rem !important;
                        @media only screen and (max-width: 1000px) {
                            height: auto !important;
                        }
                    }
                    &:hover::before{
                        content: "";
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.4);
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        
    }
    .main_img img{
        width: 100%;
        max-height: 80vw;
        object-fit: contain;
        @media only screen and (max-width: 576px) {
            max-height: 80vh;
            object-fit: cover;
        }
    }
    .page-title{
        line-height: .7;
        margin-top: 0;
        margin-bottom: 2rem;
        padding-left: 0;
        color:$red;
        font-weight: bold;
        text-transform: uppercase;
    }
    .page-body{
        margin: 0;
    }
    .page-content{
        padding:3rem 20px;
    }
    .date{
        color:$red;
        margin:10px 0;
        text-transform: uppercase;
    }
    .description{
        margin-top:3rem;
        margin-bottom:0;
    }
    
    form.floatlabel-active{
        .wtwidget-TextInput, .wtwidget-EmailInput, .wtfield-PasswordField {
            label{
                position:absolute;
                top:18px;
                left:12px;
                transition: all 0.5s ease;
                font-size:16px;
                font-weight: bold;
                text-transform: uppercase;
            }
            label.error{
                top: 60px;
                left:12px;
                font-size:12px;
            }
            &.focused, &.populated{
                label:not(.error){
                    top:0;
                    left:2px;
                    font-size:14px;
                }
         
            }
        }
    }
    .wtfield-FieldList{
        margin-top: 2rem;
        ul{
            list-style: none;
            padding:0;
            li{
                @media only screen and (max-width: 700px) {
                    margin-bottom: 2rem;
                }
                display: flex;
                & > label{
                    display: none;
                }
                table {
                    width:100%;
                    tbody{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        tr{
                            display: flex;
                            align-items: center;
                        }
                        @media only screen and (max-width: 1020px) {
                            tr{
                                display: initial;
                                width: 50%;
                                &:last-child{
                                    width: 100%;  
                                }
                            }
                        }
                        @media only screen and (max-width: 700px) {
                            tr{
                                width: 100%;
                                td{
                                    width:100%;
                                    input{
                                        margin-top: 0;
                                    }
                                }
                                select{
                                    width: 100%;
                                }
                            }
                        }

                        @media only screen and (max-width: 400px) {
                            tr{
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }  
                    }
                }
            }
        }
    }
    .form-group{
        position: relative;
        input, select, textarea{
            border: none;
            border-bottom: 1px solid #000;
            outline: none;
            width: 100%;
            margin: 1rem 0;
            font-weight: bold;
            font-size: 16px;
            font-family: proxima-nova, sans-serif;
            &:focus{
                background-color: #F2F2F2;
                border-bottom:1px solid $red;
            }
        }
        input{
            padding-bottom: 10px;
        }
        input[type=submit]{
            border: 1px solid $red;
            background-color: transparent;
            padding: 0 10px;
            cursor: pointer;
            color:$red;
            text-transform: uppercase;
            text-align: center;
            &:hover{
       
                background-color: $red;
                color: white;
            }
        }
        select{
            width: fit-content;
            padding-left: .5rem;
            text-transform: uppercase;
        }
        h3{ 
            color:$red;
            text-transform: uppercase;
            font-weight: bold;
        }
        hr{
            margin:3rem 0;
        }
       
    }
    .recaptcha-notice{
        display: flex;
        a{
            margin:0 3px;
        }
    }
    table.kms-order-line-items{
        width: 100%;
        border-collapse: collapse;
        thead{
            border-bottom: 1px solid #000;
          
            
        }
        tbody{
            // tr{
            //     display: flex;
            //     align-items: center;
            // }
            th, td{
                padding:5px;
            }
            th:first-child{
                text-align: left;
            }

        }
        tfoot{
            border-top: 1px solid #000;
        }
    }
}