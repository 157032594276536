@use 'vars' as *;

.t-sponsors{
    a{
        text-decoration:none;
    }
    .arrow-left{
        display: inline-block;
        height: 14px;
        width: 14px;
        border-top: 1px solid $red;
        border-left: 1px solid $red;
        transform: rotateY(
                0deg
            ) rotate(
                -45deg
            );
    }

    .sponsor-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 4rem 2rem;

        .title{
            color: $red;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin-top:2rem;
        }
        .overlay{
            position: relative;
           
            background-color:#F2F2F2;
            overflow: hidden;
            picture{
                padding-bottom: 100%;
                position: relative;
                display: block;
                img{
                    height: 100%!important;
                    width: 100%;
                    transition: all .5s ease-in-out;
                    object-fit: contain;
                    position: absolute;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
          
            .fallback img{
                background-color: black;
                padding:1rem;
                object-fit: contain;
            }
            
            
            
           
        }
        @media only screen and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        
        }
    }
    .event-item-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        margin-top:3rem;
        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
        .gallery{
            display: grid;
            grid-template-columns: 0.8fr 0.2fr;
            grid-gap: 2rem;
            
            & > picture > img{
                object-fit: cover;
                width: 100%;
                height: 30rem !important;
            }
            & > .selection {

                display:grid;
                grid-template-columns: 100%;
                // grid-template-rows: auto;
                grid-auto-rows:9rem;
                grid-gap: .5rem;
                & > picture {
                    position: relative;
                    cursor: pointer;
                    & > img{
                        
                        object-fit: cover;
                        width: 100%;
                        height: 100% !important;
                       
                    }
                    &:hover::before{
                        content: "";
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.4);
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        
    }

    .page-title{
        line-height: .7;
        margin-top: 0;
        margin-bottom: 2rem;
        color:$red;
        font-weight: bold;
        text-transform: uppercase;
    }

    .page-content{
        padding:3rem 20px;
    }
    .date{
        color:$red;
        margin:10px 0;
        text-transform: uppercase;
    }
    .description{
        margin-top:3rem;
        margin-bottom:0;
    }
    
    .sponsors-preview{
        margin:5rem 0;
        .title-bar{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .title{
                font-size:36px;
            }
            .red-btn{
                line-height: 34px;
            }
        }
        .cards-wrapper{
            margin-top: 2rem;
            display:grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: 30vw;
            grid-gap: 1rem;
            @media only screen and (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap:10rem 1rem;
            }
            @media only screen and (max-width: 320px) {
                grid-template-columns: 1fr;
            }
            .card{
                overflow: hidden;
                .title{
                    text-transform: uppercase;
                    text-align: center;
                    color:$red;
                    font-weight: bold;
                }
                picture{
                    position: relative;
                    display: block;
                    padding-bottom: 100%;
                    img{
                        width: 100%;
                        height: 100%!important;
                        object-fit: contain;
                        position: absolute;
                        transition: all .5s ease-in-out;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    &.fallback img{
                        background-color: black;
                        padding:1rem;
                        object-fit: contain;
                    }
                   
                }
         
            }
        }
    }
}