@use 'vars' as *;

.t-members{
    main{
        font-family: proxima-nova, sans-serif;
        a{
            text-decoration: none;
            color:$red;
            &:hover{
                color: darken($red, 20%);
            }
        }
        
    }
    .grid{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 2rem;
        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            .graphic{
                grid-row: 1;
                img{
                    height: 50vw!important;
                    width: 100vw!important;
                    object-fit:cover ;
                }
            }
        }
    }
    .graphic{
        padding-top:22px;
    }
    input, select{
        border: none;
        border-bottom: 1px solid #000;
        outline: none;
        width: 100%;
        margin: 1rem 0;
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
        
        font-family: proxima-nova, sans-serif;
        &:focus{
            background-color: #F2F2F2;
            border-bottom:1px solid $red;
        }
    }
    select{
        padding-left: .5rem;
        text-transform: uppercase;
    }

    .form-group{
        position: relative;
    }
    .membership-form-wrap{
        margin:2rem 0; 
    }
    .wtwidget-CustomHTMLWidget{
        margin-top:3rem;
        margin-bottom: .5rem;

        margin-left:.6rem;
        
        hr{
            border: none;
        }
        h3{ 
            margin-left:-.6rem;
            color: $red;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 24px;
        }
    }
    .wtfield-SelectField, .wtwidget-AttribSelect{
        margin-top:3rem;
        label{
            padding-left: .6rem;
            display: block;
        }
    }
    form.floatlabel-active{
        .wtwidget-TextInput, .wtwidget-EmailInput, .wtfield-PasswordField {
            label{
                position:absolute;
                top:18px;
                left:12px;
                transition: all 0.5s ease;
                font-size:16px;
                font-weight: bold;
                text-transform: uppercase;
            }
            &.focused, &.populated{
                label{
                    top:0;
                    left:2px;
                    font-size:14px;
                }
            }
        }
    }

    input[type=submit], .btn-primary{
        border: 1px solid $red;
        padding: 1.5rem 2rem;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 0;
        display: block;
        background-color: transparent;
        color: $red;
        transition: all .5s ease;
        text-transform: uppercase;
        &:hover{
            color: darken($red, 20%);
            border: 1px solid darken($red, 20%);
            font-size: 1.5em;
        }
    }
    
    .StripeElement {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
     
    }

    .card-element:focus{
        background-color: blue;
    }

    .g-recaptcha{
        padding-bottom:1rem;
    }

    &.register{
        .wtwidget-CustomHTMLWidget,.wtfield-SelectField label{
            margin-left: 0;
            padding-left: 0;
        }

    }

    &.password-form{
        .form-group{
            label{
                position:absolute;
                top:18px;
                left:12px;
                transition: all 0.5s ease;
                font-size:16px;
                font-weight: bold;
                text-transform: uppercase;
            }
            &.focused, &.populated{
                label{
                    top:0;
                    left:2px;
                    font-size:14px;
                }
            }
        }
    }
}
